import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import axios from "axios";
import {
  baseurl,
  OnInput,
  onInputInvalid,
  successNotify,
  uploadImage,
} from "../../constants";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Multiselect from "multiselect-react-dropdown";
import { Phone } from "react-bootstrap-icons";

// locations
import MapPicker from 'react-google-map-picker'
//, 
const DefaultLocation = { lat: 26.269952304554025, lng: 31.951373027287186 };
const DefaultZoom = 10;

export default function CreateMarket() {


  // pick location start 
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }


  const [loader, showLoader, hideLoader] = useLoader();
  ///    =========  end =====
  var [image, setImage] = useState([]);

  const [files, setFiles] = useState([]);
  // const [cardIs, setCardIds] = useState([]);
  // const [cards, setCards] = useState();
  // const [cities, setCities] = useState();
  // const [cities, setCities] = useState();
  const [categoryId, setCategoryId] = useState(0);
  const [services, setServices] = useState();
  const [serviceId, setServiceId] = useState(0);

  const [file, setFile] = useState(null);

  const [categories, setCategories] = useState();
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');






  // ! check isEmpty 
  const [setErrors] = useState({});
  const handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    if (!openTime) {
      formIsValid = false;

      errors["openTime"] = "هذا الحقل مطلوب";
    }


    if (!image) {
      formIsValid = false;

      errors["image"] = "هذا الحقل مطلوب";
    }

    // setErrors(errors);

    return formIsValid;
  };


  const getServices = async () => {
    try {
      const response = await fetch(baseurl + "servies/get-Services", {
        method: "GET",
      });
      const json = await response.json();
      console.log(json);
      setServices(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async (serviceId) => {
    try {
      const response = await fetch(
        baseurl + "category/get-categories-byservicId?serviceId=" + serviceId,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      console.log(json);
      setCategories(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };






  // ** upload image
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {
          setImage(e.data);

          console.log(e.data);
        });
    } catch (e) {
      console.log(e);
    }
  };


  const navigateTo = useNavigate();

  // ! getFormData
  const _getFormData = (event) => {
    showLoader();
    if (handleValidation() === false) {
      console.log("false");
      return;
    };
    const formdata = new FormData(event.target);;
    // formdata.append("Type", getTypeGender());
    formdata.append("CategoryId", categoryId);
    formdata.append("Status", "0");

    formdata.append("ServiceId", serviceId);
    formdata.append("FieldId", "6");
    // formdata.append("Email", "ammar");
    // formdata.append("Phone", "3434343434");
    // formdata.append("CardIds", "5");
    // formdata.append("nameAr", "MODA SALON");
    // formdata.append("images", arr.join("#"));
    formdata.append("LogoImage", image);

    return formdata

  }



  // ! add market
  const postForm = async (event) => {
    // let uploadedImage = await uploadImages(image);


  event.preventDefault();

    try {

      const response = await fetch(baseurl + "Market/add-Market", {
        method: "POST",
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
        body: _getFormData(event),
      });
      const json = await response.json();
      hideLoader();
      successNotify();
      console.log(json + "fdfndlsfjdsifsldflds");
      navigateTo("/admin/Markets");
    } catch (e) {
      hideLoader();
      console.log(e + "fgvfdgd");
    }
  };


  // ! 
  const initData = async () => {
    await getServices();
    // await getCards();
    // await getCitiss();
  };

  useEffect(() => {
    initData();
  }, []);

  const handleRemove = (id) => {
    const fileItem = files.filter((person) => person !== id);

    setFiles(fileItem);
  };

  //! date open time

  const handleOpenTime = (event) => {
    setOpenTime(event.target.value);
  };

  //! date close time

  const handleCloseTime = (event) => {
    setCloseTime(event.target.value);
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">المتاجر</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                إضافة متجر
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات المتجر</h6>
              <hr />
              <form onSubmit={postForm} encType="multipart/form-data">
                <div
                  asp-validation-summary="ModelOnly"
                  className="text-danger"
                />
                <div className="row g-3">
                  <div className="col-4">
                    <label className="form-label">اختر الفئة </label>
                    {services && (
                      <select
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        required
                        // name="ServiceId"
                        onChange={async (value) => {
                          setServiceId(value.target.value);
                          console.log(value.target.value);
                          await getCategories(value.target.value);
                        }}
                        id="school-id"
                        className="form-select"
                      >
                        <option value={""}>اختر</option>
                        {services.map((row) => (
                          <option key={row.id} value={row.id}>
                            {row.nameAr}
                          </option>
                        ))}
                      </select>
                    )}

                    <span
                      asp-validation-for="FieldId"
                      className="text-danger"
                    />
                  </div>

                  {/* categories  */}
                  <div className="col-4">
                    <label className="form-label">اختر القسم </label>
                    {categories && (
                      <select
                        // name="categoryId"
                        onChange={(value) => {
                          setCategoryId(value.target.value);
                        }}
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        // required
                        id="school-id"
                        className="form-select"
                      >
                        <option value={""}>اختر</option>
                        {categories.categories.map((row) => (
                          <option key={row.id} value={row.id}>
                            {row.nameAr}
                          </option>
                        ))}
                      </select>
                    )}{" "}
                    <span
                      asp-validation-for="FieldId"
                      className="text-danger"
                    />
                  </div>

                  {/* end */}
                  {/* subscrip cardd */}
                  {/* <div className="col-6">
                    <label className="form-label">الاشتراكات </label>
                    <Multiselect
                      placeholder="اختار الريكوفري كارد"
                      style={{
                        searchBox: {
                          border: "none",
                          "border-bottom": "1px solid blue",
                          "border-radius": "0px",
                        },
                      }}
                      displayValue="nameAr"
                     
                      onRemove={(event) => {
                        console.log(event);
                      }}
                      onSelect={handleChangeMultiSelect}
                      options={cards}
                     
                    />
                  </div> */}
                  {/*  type Gender */}

                  {/* <div className="col-4">
                    <label className="form-label"> الاشتراكات </label>
                    {cards && (
                      <select
                    
                        onChange={(value) => {
                          setCardId(value.target.value);
                        }}
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        // required
                        id="school-id"
                        className="form-select"
                      >
                        <option value={""}>اختر</option>
                        {cards.map((row) => (
                          <option key={row.id} value={row.id}>
                            {row.nameAr}
                          </option>
                        ))}
                      </select>
                    )}{" "}
                    <span
                      asp-validation-for="FieldId"
                      className="text-danger"
                    />
                  </div>
                 */}
                  {/* <div className="col-6">
                    <label className="form-label"> اختار المحافظة </label>
                    {cities && (
                      <select
                        //  name="cardId"
                        onChange={(value) => {
                          console.log(value.target.value);
                          setCityId(value.target.value);
                        }}
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        // required
                        id="school-id"
                        className="form-select"
                      >
                        <option value={""}>اختر</option>
                        {cities.map((row) => (
                          <option key={row.id} value={row.id}>
                            {row.nameAr}
                          </option>
                        ))}
                      </select>
                    )}{" "}
                    <span
                      asp-validation-for="FieldId"
                      className="text-danger"
                    />
                  </div> */}

                  <div className="col-6">
                    <label className="form-label">الإسم باللغة العربية </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="NameAr"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      الإسم باللغة الإنجليزية{" "}
                    </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="NameEng"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">وصف عن المتجر </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="AboutAr"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Info" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">وصف عن المتجر بالإنجليزية </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="AboutEng"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Info" className="text-danger" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">رقم التواصل </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="Phone"
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="col-6">
                    <label className="form-label"> البريد الإلكتروني </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="Email"
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="col-6">
                    <label className="form-label"> العنوان  </label>
                    <input
                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="AddressName"
                      type="text"
                      className="form-control"
                    />
                  </div>



                  <div className="col-6">
                    <label className="form-label">الموقع الإلكتروني </label>
                    <input

                      onInvalid={onInputInvalid}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="LinkInstgram"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label">الترتيب </label>
                    <input
                      autoComplete="off"
                      name="Order"
                      type="Number"
                      className="form-control"
                    />
                  </div>

                  {/* open time ans close */}
                  <div className="col-6">
                    <label className="form-label">وقت فتح المتجر</label>
                    <input
                      required
                      type="time"
                      name="OpenTime"
                      id="dateInput"
                      value={openTime}
                      onChange={handleOpenTime}
                    />

                  </div>

                  <div className="col-6">
                    <label className="form-label">وقت اغلاق المتجر</label>
                    <input
                      required
                      type="time"
                      name="OpenClose"
                      id="dateInput"
                      value={closeTime}
                      onChange={handleCloseTime}
                    />

                  </div>

                  {/*  photo */}
                  <div className="col-6">
                    <label className="form-label">شعار المتجر </label>
                    <div className="custom-file">
                      <input
                        required
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        onChange={(e) => {
                          setFile(URL.createObjectURL(e.target.files[0]));
                          uploadImage(e.target.files[0]);
                        }}
                      />

                      <img src={file} width={64} height={64} alt="" />
                    </div>
                  </div>




                  <div>
                    {files ? (
                      files.map((item, i) => (
                        <div key={i} className="col">
                          <div className="image-style">
                            <button
                              type="button"
                              onClick={() => handleRemove(item)}
                              class="btn-close btn-close"
                              aria-label="Close"
                            ></button>
                            <img src={URL.createObjectURL(item)} alt="" />
                          </div>
                        </div>
                      ))
                    ) : (
                      <h1>jdasdas</h1>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="d-grid">
                      <input
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        required
                        type="submit"
                        value="حفظ"
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
